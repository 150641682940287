<template>
  <div class="loginPage_wrapper">
    <el-card class="loginPage_box">
      <div class="project_logo">
        <div class="loginPage_logo logo"></div>
      </div>
      <slot name="loginForm"></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'login-teplo'
}
</script>

<style lang="scss" scoped>
.logo {
    background: url(./resources/teplo_logo.png) no-repeat;
    width: 100%;
    background-position: center;
}
</style>
